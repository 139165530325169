body {
  font-family: 'Prompt', sans-serif;
}

canvas.marks {
  max-width: 100%!important;
  height: auto!important;
}

.is-danger {
  background-color: #000;
}