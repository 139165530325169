@charset "utf-8";


.tile.is-gapless {
    .tile.is-parent {
      padding: 0;
    }
    
    .tile.is-vertical>.tile.is-child {
      margin-bottom: 0 !important;
    }
    
    .notification {
      border-radius: 0;
    }
}

.has-text-header {
    color: #9fcab7 !important;
}

.hero.has-background {
    position: relative;
    overflow: hidden;
  }
  .hero-background {
    position: absolute;
    object-fit: cover;
    object-position: center center;
    width: 100%;
    height: 100%;
  }
  .hero-background.is-transparent {
    opacity: 0.5;
  }

$grey-dark: #2b2b2b;
$grey-lighter: #faf5e6;

$primary: #658b6e;
$danger: #b26265;

$panel-radius: 0px;
$panel-heading-size: 1.5em;

$size-1: 10em;
$size-2: 5em;
$size-3: 3em;
$size-4: 2em;
$size-5: 1.5em;

@import "../node_modules/bulma/bulma.sass";